import { CrmConfigSchema } from "./ConfigSchema/CrmConfigSchema";
import { PpConfigSchema } from "./ConfigSchema/PpConfigSchema";
import Dashboard from "./Dashboard/Dashboard";

export const PluginsMap: any = {
    'custom.routes': {
        '/c/dashboard/main': Dashboard
    },
    config: {
        paypal: PpConfigSchema,
        crm: CrmConfigSchema,
    }
}